<template>
  <el-dialog
    v-model="winShow" width="800px"
    :close-on-click-modal="false"
    :title="t('project.detail')"
    :close-on-press-escape="false">
    <el-descriptions :column="2" border>
      <template v-for="(item, index) in descriptions" :key="index">
        <template v-if="item.custem">
          <el-descriptions-item
            v-if="!!!item.hidden"
            :label="item.label"
            :span="item.span || ''">
            <div v-html="item.custem() || '-'"></div>
          </el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item
            v-if="!!!item.hidden"
            :label="item.label"
            :span="item.span || ''">
            {{ projectDetail[item.prop] || '-' }}
          </el-descriptions-item>
        </template>
      </template>
    </el-descriptions>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject } from 'vue'
import { AuthManagement } from '@/utils/api'
import { changeUTCtime } from '@/utils/format'

const t = inject('t')
const winShow = ref(false)
const projectDetail = ref({});
const descriptions = reactive([
  { // 创建时间
    label: t('table.creation_time'),
    prop: "created_at",
    custem: () => {
      return projectDetail.value.created_at ? changeUTCtime(projectDetail.value.created_at) : '-'
    }
  },
  { // 项目编号
    label: t('project.project_code'),
    prop: 'id'
  },
  { // 项目名称
    label: t('project.project_name'),
    prop: 'name'
  },
  { // 项目状态
    label: t('project.status'),
    prop: 'effectuate',
      custem: () => {
        return projectDetail.value.effectuate ? `<span class="finished">${t('table.enable')}</span>` : `<span class="faild">${t('form.close')}</span>`
      }
  },
  { // 产品名称
    label: t('product.product_name'),
    prop: 'product_name',
    custem: () => {
      return projectDetail.value.product ? `${projectDetail.value.product.deployment.name}/${projectDetail.value.product.category.name}/${projectDetail.value.product.name}` : '-'
    }
  },
  { // 企业PO号
    label: t('project.customer_po'),
    prop: 'product_name',
    custem: () => {
      return projectDetail.value.params ? projectDetail.value.params.customer_po : '-'
    }
  },
  { // 时区
    label: t('system.time_zone_project'),
    prop: 'time_zone_project',
    span: 2,
    custem: () => {
      return projectDetail.value.params ? projectDetail.value.params.display_time_zone : '-'
    }
  },
  { // 所属子用户
    label: t('project.sub_user'),
    prop: 'sub_user_name',
    custem: () => {
      return projectDetail.value.sub_user ? projectDetail.value.sub_user.name : '-'
    }
  },
  { // 子用户邮箱
    label: t('user.sub_user_mail'),
    prop: 'sub_user_mail',
    custem: () => {
      return projectDetail.value.sub_user ? projectDetail.value.sub_user.mail : '-'
    }
  },
  { // 产品用户名
    label: t('product.user_product'),
    prop: 'saas_name',
    custem: () => {
      return projectDetail.value.params ? projectDetail.value.params.saas_name : '-'
    }
  },
  { // 项目邮箱
    label: t('project.project_mail'),
    prop: 'mail'
  },
  { // 备注
    label: t('user.notes'),
    prop: 'comment',
    span: 2
  },
  { // 注册码
    label: t('project.license'),
    prop: 'lic_key'
  }
])

// 获取详情接口
const getFailDetail = (id) => {
  AuthManagement.ProjectDetail(id).then(res => {
    projectDetail.value = res
  })
}
// 打开弹框
const openWin = (data) => {
  winShow.value = true
  getFailDetail(data)
}

// 关闭弹框
const closeWin = () => {
  winShow.value = false
  descriptions.forEach(item => {
    item.value = ""
  })
}

defineExpose({
  openWin: openWin
})
</script>